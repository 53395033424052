import React, { createContext } from "react";
import PropTypes from "prop-types";
import { get } from "utils/api";
import useFetch from "hooks/useFetch";

export const PatientProfileContext = createContext();

const PatientProfileProvider = ({ patientId, patientInfo, children }) => {
  const patientFormAttributesAPI = useFetch({
    apiFn: patientId => get(`patients/${patientId}/form-attributes`),
    defaultValue: {
      company_insurances: [],
      accessible_users: { dmes: [], s3_users: [], compliance_coaches: [] },
      company_order_settings: {},
      supply_period_options: []
    }
  });

  const fetchPatientHCPCHistoryAPI = useFetch({
    apiFn: patientId => get(`patients/${patientId}/hcpcs-ordered`),
    defaultValue: { eligibility: [], outreachDate: "" }
  });

  React.useEffect(() => {
    patientFormAttributesAPI.fetchData(patientId);
    fetchPatientHCPCHistoryAPI.fetchData(patientId);
  }, [patientId]);

  return (
    <PatientProfileContext.Provider
      value={{
        companyInsurances:
          patientFormAttributesAPI.response.company_insurances ?? [],
        accessible_users: patientFormAttributesAPI.response
          .accessible_users ?? {
          dmes: [],
          s3_users: [],
          compliance_coaches: []
        },
        company_order_settings:
          patientFormAttributesAPI.response.company_order_settings ?? {},
        supply_period_options:
          patientFormAttributesAPI.response.supply_period_options ?? [],
        patientFormAttributesAPI,
        fetchPatientHCPCHistoryAPI
      }}
    >
      {children}
    </PatientProfileContext.Provider>
  );
};

PatientProfileProvider.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object,
  children: PropTypes.node
};

export default PatientProfileProvider;
