import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { setFilter, initFilters } from "actions/inventory";
import { useFormikContext } from "formik";
import ProductSelect from "components/forms/add-equipment/product-select";
import FormikField from "components/forms/formik-field";

const SelectEquipment = ({
  filterState,
  setFilter,
  equipmentTypeOptions,
  manufacturerOptions,
  patientId
}) => {
  const { handleChange, handleBlur, setFieldValue } = useFormikContext();
  const [selectProductByID, setSelectProductByID] = React.useState(false);

  React.useEffect(() => {
    setFilter("compatibleEquipment", false);
    setFilter("product_type", "");
    setFilter("manufacturer_id", "");
  }, []);
  React.useEffect(() => {
    setFieldValue("product_id", "");
  }, [selectProductByID]);

  return (
    <div>
      <div
        style={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "150px 150px",
          marginBottom: 20
        }}
      >
        <label>
          Select Product
          <input
            style={{ margin: 0, marginLeft: 10 }}
            type="radio"
            checked={!selectProductByID}
            onChange={() => {
              setSelectProductByID(false);
            }}
          />
        </label>
        <label>
          Select Product by ID
          <input
            style={{ margin: 0, marginLeft: 10 }}
            type="radio"
            checked={selectProductByID}
            onChange={() => {
              setSelectProductByID(true);
            }}
          />
        </label>
      </div>
      {selectProductByID ? (
        <div>
          <FormikField type="text" name="product_id" label="Product ID" />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gridGap: 20
            }}
          >
            <div>
              <label> Equipment Type </label>
              <select
                onChange={e => {
                  setFilter("product_type", e.target.value);
                }}
              >
                <option />
                {equipmentTypeOptions.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label> Manufacturer </label>
              <select
                onChange={e => {
                  setFilter("manufacturer_id", e.target.value);
                }}
              >
                <option />
                {manufacturerOptions.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <ProductSelect
            name="product_id"
            label="Product *"
            filter={{ ...filterState }}
            onChange={handleChange}
            onBlur={handleBlur}
            patientId={patientId}
          />
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    filterState: selectors.getInventoryFilterState(state),
    equipmentTypeOptions: selectors.getEquipmentTypeOptions(state),
    manufacturerOptions: selectors.getManufacturerOptions(state)
  }),
  { initFilters, setFilter }
)(SelectEquipment);

SelectEquipment.propTypes = {
  filterState: PropTypes.object,
  setFilter: PropTypes.func,
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object),
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object),
  patientId: PropTypes.string
};
