import React from "react";
import PropTypes from "prop-types";
import { SumBlock } from "components/ui";
import { path } from "ramda";
import { formatPercantage } from "utils/misc";

const ContactSummary = ({ contactsReport }) => (
  <div className="contact-summary-blocks">
    <SumBlock
      title="Total"
      value={contactsReport.exposed_contacts_success_total}
      subLabel={`Unique Patients: 
      ${path(["unique_patients_contacted"], contactsReport)}`}
    />
    <SumBlock
      title="SMS"
      value={path(["sms", "success_total"], contactsReport)}
    />
    <SumBlock
      title="Emails"
      value={path(["email", "success_total"], contactsReport)}
    />
    <SumBlock
      title="Outgoing Calls"
      value={path(["phone", "outgoing_call", "successes"], contactsReport)}
      subLabel={`Voicemails: 
      ${path(["phone", "left_voicemail", "successes"], contactsReport)}
      (${formatPercantage(
        path(["phone", "left_voicemail", "successes"], contactsReport),
        path(["phone", "outgoing_call", "successes"], contactsReport)
      )})`}
    />
    <SumBlock
      title="Incoming Calls"
      value={path(["phone", "incoming_call", "successes"], contactsReport)}
    />
  </div>
);

export default ContactSummary;

ContactSummary.propTypes = {
  contactsReport: PropTypes.shape({
    phone: PropTypes.object,
    sms: PropTypes.object,
    email: PropTypes.object,
    exposed_contacts_success_total: PropTypes.number
  }).isRequired
};
