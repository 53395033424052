import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createBranch } from "actions/company";
import PageHeader from "components/ui/page-header";
import BranchForm from "./branch-form";
import { getRouteParam } from "routes";
import { selectors } from "reducers";

class CreateBranch extends Component {
  createBranch = formValues => {
    const { createBranch } = this.props;
    const companyId = getRouteParam(this.props, "id");
    return createBranch({ companyId, branch: formValues });
  };

  render() {
    return (
      <div className="large-12 columns">
        <PageHeader title={`Create branch`} />
        <BranchForm
          onSubmit={this.createBranch}
          initialValues={{
            name: "",
            tag: "",
            active: true,
            outbound_phone_number: "",
            contact_number: "",
            insurance: { },
            address: {
              street_address: "",
              city: "",
              state: "",
              zip: ""
            },
            bUsesBranchFulfillment: this.props.company.bUsesBranchFulfillment,
            cpap_account_number: "",
            uses_cpap_fulfillment: this.props.company.uses_cpap_fulfillment,
            uses_fulfillment: this.props.company.uses_fulfillment,
            independance_account_number: "",
            uses_independence_fulfillment: this.props.company.uses_independence_fulfillment,
            internal_account_number: "",
            uses_internal_fulfillment: this.props.company.uses_internal_fulfillment,
            mckesson_account_number: "",
            uses_mckesson_fulfillment: this.props.company.uses_mckesson_fulfillment,
            ppm_account_number: "",
            uses_ppm_fulfillment: this.props.company.uses_ppm_fulfillment,
            vgm_account_number: "",
            uses_vgm_fulfillment: this.props.company.uses_vgm_fulfillment
          }}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      company: selectors.getCompanyProfile(state, getRouteParam(props, "id"))
    }),
    { createBranch }
  )(CreateBranch)
);

CreateBranch.propTypes = {
  branch: PropTypes.object,
  company: PropTypes.object,
  createBranch: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired
};