import C from "action-types";
import { makeFetchTuple, makeFetchCreator } from "actions/fetch";
import { entityTransform, entitiesTransform } from "actions/transform";
import { post, put, del } from "utils/api";
import * as msg from "actions/message";
import uniqueId from "lodash/uniqueId";
import map from "lodash/map";
import flatMap from "lodash/fp/flatMap";
import keyBy from "lodash/fp/keyBy";
import flow from "lodash/fp/flow";
import { prop, propOr } from "ramda";
import { errorMessage } from "actions/message";
import { getApiErrorMessage } from "utils/misc";
import { browserHistory } from "browser-history";

const transformPatientEntities =
  (key, responseKey) =>
    (response, { patientId }) => ({
      entities: { [key]: { [patientId]: prop(responseKey, response) } }
    });

export const postPatientAccess = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/access`,
  baseType: C.PATIENT.ACCESS,
  method: post,
  cache: true,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const [fetchPatientProfileInfo, invalidatePatientProfileInfo] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/info`,
    baseType: C.PATIENT.GET_PROFILE_INFO,
    transform: entityTransform("patients"),
    mapActionToKeyIn: ({ patientId }) => [patientId],
    meta: true
  });

export const [fetchPatientOrderHistory, invalidatePatientOrderHistory] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/hcpcs-ordered`,
    baseType: C.PATIENT.GET_PROFILE_ORDER_HISTORY,
    transform: transformPatientEntities("eligiblity", "eligiblity"),
    mapActionToKeyIn: ({ patientId }) => [patientId],
    onErrorAction: e =>
      errorMessage(
        "Error retrieving patients HCPC order history: " + getApiErrorMessage(e)
      ),
    meta: true
  });

export const [fetchPatientNotes, invalidatePatientNotes] = makeFetchTuple({
  url: ({ patientId }) => `patients/${patientId}/patient-notes`,
  baseType: C.PATIENT.GET_NOTES,
  transform: transformPatientEntities("patientNotes", "patientNotes"),
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const [fetchPatientOrders, invalidatePatientOrders] = makeFetchTuple({
  url: ({ patientId }) => `patients/${patientId}/orders`,
  baseType: C.PATIENT.GET_ORDERS,
  transform: entitiesTransform("orders"),
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const [fetchPatientWalkInOrders, invalidatePatientWalkInOrders] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/walk-in-orders`,
    baseType: C.PATIENT.GET_WALK_IN_ORDERS,
    transform: transformPatientEntities("walkInOrders", "walkInOrders"),
    mapActionToKeyIn: ({ patientId }) => [patientId]
  });

/// Contact Records here
export const [fetchPatientContactRecords, invalidatePatientContactRecords] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/contact-records`,
    baseType: C.PATIENT.GET_CONTACT_RECORDS,
    transform: transformPatientEntities("contactRecords", "contactRecords"),
    mapActionToKeyIn: ({ patientId }) => [patientId]
  });

// export const [fetchPatientHCPCSRecords, invalidatePatientHCPCSRecords] =
//   makeFetchTuple({
//     url: ({ patientId }) => `patients/${patientId}/hcpcs`,
//     baseType: C.PATIENT.GET_HCPCS_RECORDS,
//     transform: transformPatientEntities("hcpcsRecords", "hcpcsRecords"),
//     mapActionToKeyIn: ({ patientId }) => [patientId]
//   });
export const [fetchPatientXZeroRecords, invalidatePatientXZeroRecords] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/XZEROS`,
    baseType: C.PATIENT.GET_XZERO_RECORDS,
    transform: transformPatientEntities("xZeros", "xZerosList"),
    mapActionToKeyIn: ({ patientId }) => [patientId]
  });

export const [fetchPatientChangeLogs, invalidatePatientChangeLogs] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/logs/change`,
    baseType: C.PATIENT.GET_CHANGE_LOGS,
    transform: transformPatientEntities("changeLogs", "logs"),
    mapActionToKeyIn: ({ patientId }) => [patientId],
    cache: false
  });

export const [fetchPatientAccessLogs, invalidatePatientAccessLogs] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/logs/access`,
    baseType: C.PATIENT.GET_ACCESS_LOGS,
    transform: transformPatientEntities("accessLogs", "logs"),
    mapActionToKeyIn: ({ patientId }) => [patientId]
  });

export const [fetchPatientEquipment, invalidatePatientEquipment] =
  makeFetchTuple({
    url: ({ patientId }) => `patients/${patientId}/equipment`,
    baseType: C.PATIENT.GET_EQUIPMENT,
    transform: entitiesTransform("equipment"),
    mapActionToKeyIn: ({ patientId }) => [patientId]
  });

export const [
  fetchPatientMaskFittingResults,
  invalidatePatientMaskFittingResults
] = makeFetchTuple({
  url: ({ patientId }) => `autofit/results/${patientId}`,
  baseType: C.PATIENT.GET_MASK_FITTING_RESULTS,
  transform: ({ results }, { patientId }) => ({
    entities: {
      maskFittingResults: { [patientId]: results }
    }
  }),
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const refreshAllPatientProfile = params => dispatch => {
  // No invalidator for patient access. It should be called once only.
  const invalidators = [
    invalidatePatientProfileInfo,
    invalidatePatientNotes,
    invalidatePatientOrders,
    invalidatePatientWalkInOrders,
    invalidatePatientOrderHistory,
    // invalidatePatientSpecialFields,
    invalidatePatientContactRecords,
    invalidatePatientChangeLogs,
    invalidatePatientAccessLogs,
    invalidatePatientEquipment,
    // invalidatePatientHCPCSRecords,
    invalidatePatientXZeroRecords,
    invalidatePatientMaskFittingResults
  ];
  invalidators.forEach(i => dispatch(i(params)));

  const fetchers = [
    postPatientAccess,
    fetchPatientProfileInfo,
    fetchPatientNotes,
    fetchPatientOrders,
    fetchPatientWalkInOrders,
    fetchPatientOrderHistory,
    // fetchPatientSpecialFields,
    fetchPatientEquipment,
    // fetchPatientHCPCSRecords,
    fetchPatientXZeroRecords,
    fetchPatientMaskFittingResults
  ];
  return Promise.all(fetchers.map(f => dispatch(f(params))));
};

export const fetchPatient = params => dispatch => {
  // `fetchPatientContactRecords`, `fetchPatientChangeLogs` and `fetchPatientAccesslogs`
  // intentionally left out. It is called in the `LogsTab`
  // component. Still invalidate in `invalidatePatient`.
  const fetchers = [
    postPatientAccess,
    fetchPatientProfileInfo,
    fetchPatientNotes,
    fetchPatientOrders,
    fetchPatientWalkInOrders,
    fetchPatientProfileInfo,
    fetchPatientOrderHistory,
    // fetchPatientSpecialFields,
    // fetchPatientContactRecords,
    fetchPatientEquipment,
    // fetchPatientHCPCSRecords,
    fetchPatientXZeroRecords,
    fetchPatientMaskFittingResults
  ];
  return Promise.all(fetchers.map(f => dispatch(f(params))));
};

export const invalidatePatient = params => dispatch => {
  // No invalidator for patient access. It should be called once only.
  dispatch({
    // delete properties from redux.
    type: "DELETE_PATIENT_PROFILE",
    payload: {
      deletions: {
        accessLogs: [params.patientId],
        changeLogs: [params.patientId],
        contactRecords: [params.patientId],
        hcpcsRecords: [params.patientId],
        patientNotes: [params.patientId],
        patients: [params.patientId],
        walkInOrders: [params.patientId],
        maskFittingResults: [params.patientId]
      }
    }
  });
  const invalidators = [
    invalidatePatientProfileInfo,
    invalidatePatientNotes,
    invalidatePatientOrders,
    invalidatePatientWalkInOrders,
    // invalidatePatientSpecialFields,
    invalidatePatientContactRecords,
    invalidatePatientChangeLogs,
    invalidatePatientAccessLogs,
    invalidatePatientEquipment,
    //invalidatePatientHCPCSRecords,
    invalidatePatientXZeroRecords,
    invalidatePatientOrderHistory,
    invalidatePatientMaskFittingResults
  ];
  invalidators.forEach(i => dispatch(i(params)));
};

const addPatientNote = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/notes`,
  baseType: C.PATIENT.ADD_NOTE,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const createPatientNote =
  ({ patientId, noteContent }) =>
    dispatch =>
      dispatch(
        addPatientNote({ patientId, payload: { content: noteContent } })
      ).then(() => dispatch(invalidatePatientNotes({ patientId })));
////////////////////////////////////////////////////////////////////////
const postResupplyEmailToPatient = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_resupply_email`,
  baseType: C.PATIENT.SEND_RESUPPLY_EMAIL,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

const SUCCESS_MESSAGE = "Message sent!";
const FAILURE_MESSAGE = "Sorry, your message could not be sent at this time.";

export const resupplyEmailToPatient =
  ({ patientId }) =>
    dispatch =>
      dispatch(postResupplyEmailToPatient({ patientId }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(err =>
          dispatch(msg.errorMessage(getApiErrorMessage(err, FAILURE_MESSAGE)))
        );

////////////////////////////////////////////////////////////////////////////////
const postWelcomeEmailToPatient = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_welcome_email`,
  baseType: C.PATIENT.SEND_RESUPPLY_EMAIL,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const welcomeEmailToPatient =
  ({ patientId }) =>
    dispatch =>
      dispatch(postWelcomeEmailToPatient({ patientId }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(FAILURE_MESSAGE)));

////////////////////////////////////////////////////////////////////////////////
const postTextConsentToPatient = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_text_consent_email`,
  baseType: C.PATIENT.SEND_TEXT_CONSENT_EMAIL,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

const TEXT_CONSENT_SUCCESS_MESSAGE = "Text Consent Email sent!";
const TEXT_CONSENT_FAILURE_MESSAGE =
  "Sorry, your email could not be sent at this time.";

export const sendtextConsentEmailToPatient =
  ({ patientId }) =>
    dispatch =>
      dispatch(postTextConsentToPatient({ patientId }))
        .then(() => dispatch(msg.message(TEXT_CONSENT_SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(TEXT_CONSENT_FAILURE_MESSAGE)));
////////////////////////////////////////////////////////////////////////////////

const postRemoteSetupToPatient = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_remote_setup_email`,
  baseType: C.PATIENT.SEND_REMOTE_SETUP_EMAIL,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

const REMOTE_SETUP_SUCCESS_MESSAGE = "Remote Setup Email sent!";
const REMOTE_SETUP_FAILURE_MESSAGE =
  "Sorry, your email could not be sent at this time.";

export const sendRemoteSetupEmailToPatient =
  ({ patientId }) =>
    dispatch =>
      dispatch(postRemoteSetupToPatient({ patientId }))
        .then(() => dispatch(msg.message(REMOTE_SETUP_SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(REMOTE_SETUP_FAILURE_MESSAGE)));
////////////////////////////////////////////////////////////////////////////////

const postResetPasswordEmailToPatient = makeFetchCreator({
  url: () => `patient_portal/mobile/forgot_password`,
  baseType: C.PATIENT.SEND_RESET_PASSWORD_EMAIL,
  method: post,
  mapActionToKeyIn: ({ passwordResetPayload }) => [passwordResetPayload]
});

export const resetPasswordEmail = email => dispatch =>
  dispatch(postResetPasswordEmailToPatient({ payload: { email } }))
    .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
    .catch(() => dispatch(msg.errorMessage(FAILURE_MESSAGE)));

/////////////////////////////////////////////////////////////////////////////////
const postTextPatient = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_text_message`,
  baseType: C.PATIENT.SEND_TEXT,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

const postTextConsentPatient = makeFetchCreator({
  url: ({ patientId, phone }) =>
    `twilio/New/Text/SendConfirmation/${patientId}/${phone}`,
  baseType: C.PATIENT.SEND_TEXT_CONSENT_TEXT,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

const postKudosText = makeFetchCreator({
  url: ({ patientId }) =>
    `twilio/New/Text/SendComplianceKudosText/${patientId}`,
  baseType: C.PATIENT.SEND_KUDOS_TEXT,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});
export const textPatient =
  ({ patientId, message }) =>
    dispatch =>
      dispatch(postTextPatient({ patientId, payload: { message } }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(FAILURE_MESSAGE)));

export const textKudosPatient =
  ({ patientId, message }) =>
    dispatch =>
      dispatch(postKudosText({ patientId, payload: { message } }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(e => dispatch(msg.errorMessage(e.response.body.status)));

export const textConsentPatient =
  ({ patientId, phone, message }) =>
    dispatch =>
      dispatch(postTextConsentPatient({ patientId, phone, payload: { message } }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(FAILURE_MESSAGE)));

const postPushNotifyPatient = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_push_notification`,
  baseType: C.PATIENT.SEND_PUSH_NOTIFICATION,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const pushNotifyPatient =
  ({ patientId, message }) =>
    dispatch =>
      dispatch(postPushNotifyPatient({ patientId, payload: { message } }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(FAILURE_MESSAGE)));

const postSendAppDownloadEmail = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/send_app_download_email`,
  baseType: C.SEND_APP_DOWNLOAD_EMAIL,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId]
});

export const sendAppDownloadEmail =
  ({ patientId }) =>
    dispatch =>
      dispatch(postSendAppDownloadEmail({ patientId }))
        .then(() => dispatch(msg.message(SUCCESS_MESSAGE)))
        .catch(() => dispatch(msg.errorMessage(FAILURE_MESSAGE)));

// const putPatient = makeFetchCreator({
//   url: ({ patientId }) => `patients/${patientId}`,
//   baseType: C.PATIENT.UPDATE,
//   method: put,
//   mapActionToKeyIn: ({ patientId }) => [patientId]
// });

export const updatePatient =
  ({ patientId, updates }) =>
    async dispatch => {
      try {
        const res = await put(`patients/${patientId}`, updates);

        if (res && res.id && res.id.formsWereUpdated) {
          dispatch(msg.message(res.id.formsWereUpdated));
        }

        dispatch(msg.message("Successfully updated patient"));
        dispatch(invalidatePatientProfileInfo({ patientId }));
        dispatch(fetchPatientProfileInfo({ patientId }));
        // dispatch(invalidatePatientHCPCSRecords({ patientId }));
        // dispatch(fetchPatientHCPCSRecords({ patientId }));
        return true;
      } catch (err) {
        dispatch(
          msg.errorMessage(
            `Failed to update patient. ${err.response.body.status}`
          )
        );
        return false;
      }
    };

export const deleteSpecialInstruction =
  ({ patientId, guid }) =>
    async dispatch => {
      await del(`patients/specialInstructions/${guid}`);
      dispatch(invalidatePatientProfileInfo({ patientId }));
      dispatch(fetchPatientProfileInfo({ patientId }));
    };

const postNewPatient = makeFetchCreator({
  url: "patients",
  baseType: C.PATIENT.CREATE,
  method: post,
  mapActionToKeyIn: () => [uniqueId()]
});

export const createNewPatient = patient => dispatch =>
  dispatch(postNewPatient({ payload: patient }));

export const setPatientForm =
  ({ patientId }) =>
    dispatch => {
      dispatch(invalidatePatientProfileInfo({ patientId }));
      dispatch(fetchPatientProfileInfo({ patientId })).then(() =>
        dispatch({
          type: C.PATIENT.SET_FORM,
          patientId
        })
      );
    };

const makeExtract = key =>
  flow(
    attributes =>
      map(attributes, ({ value, text, ...rest }, index) => ({
        [key]: value,
        name: text,
        formAttributeIndex: index,
        ...rest
      })),
    keyBy(key)
  );

const guidExtract = makeExtract("GUID");

export const formAttributeTransform = response => ({
  hold_reasons: keyBy("value")(response.hold_reasons),
  task_actions: keyBy("value")(response.task_actions),
  companies: guidExtract(response.companies),
  company_users_dme: keyBy("value")(response.company_users.dmes),
  company_users_s3: keyBy("value")(response.company_users.s3_users),
  branches: flow(
    flatMap(c =>
      c.branches.map((b, index) => ({
        ...b,
        GUID: b.value,
        name: b.text,
        company: c.value,
        formAttributeIndex: index
      }))
    ),
    keyBy("GUID")
  )(response.companies),
  insurance: guidExtract(response.insurance),
  no_mask_reasons: propOr([], "reason_no_mask", response),
  fulfillment_companies: propOr([], "fulfillment_companies", response),
  hcpcs_list: propOr([], "hcpcs_list", response).map(
    ({ text, parent, ...rest }) => ({
      ...rest,
      text: text.trim(),
      value: text.trim(),
      parent: parent
    })
  ),
  call_disposition_reasons: propOr([], "call_disposition_reasons", response),
  transfer_reasons: propOr([], "transfer_reasons", response),
  sleepCoaches: flow(
    attributes =>
      map(attributes, ({ value, text, extension, active, ...rest }, index) => ({
        GUID: value,
        name: text,
        extension,
        active,
        formAttributeIndex: index,
        ...rest
      })),
    keyBy("GUID")
  )(response.sleep_coaches),
  team_names: flow(
    attributes =>
      map(attributes, ({ value, text, ...rest }, index) => ({
        GUID: value,
        name: text,
        formAttributeIndex: index,
        ...rest
      })),
    keyBy("GUID")
  )(response.team_names),
  therapists: flow(
    attributes =>
      map(attributes, ({ value, text, company_id, ...rest }, index) => ({
        ...rest,
        GUID: value,
        name: text,
        company: company_id,
        formAttributeIndex: index
      })),
    keyBy("GUID")
  )(response.therapists),
  patient_types: keyBy("iPatientTypeId")(response.patient_types)
});

export const fetchPatientFormAttributes = makeFetchCreator({
  url: "patients/form_attributes",
  baseType: C.PATIENT.FORM_ATTRIBUTES,
  // TODO what is the reason for storing formAttributes as an entity and in formAttributes reducer
  transform: response => ({ entities: formAttributeTransform(response) }),
  mapActionToKeyIn: () => []
});

export const QUEUE_PATIENTS = "QUEUE_PATIENTS";
export const queuePatients = patientIds => ({
  type: QUEUE_PATIENTS,
  payload: { patientIds }
});

export const FLUSH_PATIENT_QUEUE = "FLUSH_PATIENT_QUEUE";
export const flushPatientQueue = () => ({
  type: FLUSH_PATIENT_QUEUE
});

export const sendPatientEmailFromTemplate =
  (patientID, templateID, values = {}) =>
    async dispatch => {
      try {
        await post("/patients/send_email", {
          patientID,
          templateID,
          parameters: Object.keys(values).map(v => ({ key: v, value: values[v] }))
        });
        dispatch(msg.message("Successfully sent email"));
      } catch (e) {
        dispatch(
          msg.errorMessage("Sorry something went wrong, failed to send email")
        );
      }
    };

const postEquipmentItemById = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/equipment_by_id`,
  baseType: C.PATIENT.ADD_EQUIPMENT_ITEM_BY_ID,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId, uniqueId()]
});

export const addEquipmentItemById =
  ({ patientId, productId }) =>
    async dispatch => {
      try {
        await dispatch(
          postEquipmentItemById({
            patientId,
            payload: {
              manufacturerID: productId
            }
          })
        );
        dispatch(invalidatePatientEquipment({ patientId }));
      } catch (error) {
        dispatch(errorMessage(getApiErrorMessage(error)));
      }
    };
const postEquipmentItem = makeFetchCreator({
  url: ({ patientId }) => `patients/${patientId}/equipment`,
  baseType: C.PATIENT.ADD_EQUIPMENT_ITEM,
  method: post,
  mapActionToKeyIn: ({ patientId }) => [patientId, uniqueId()]
});

export const addEquipmentItem =
  ({ patientId, type, productId }) =>
    dispatch =>
      dispatch(
        postEquipmentItem({
          patientId,
          payload: {
            type,
            product_id: productId
          }
        })
      ).then(() => dispatch(invalidatePatientEquipment({ patientId })));

const delPatientEquipmentItem = makeFetchCreator({
  url: ({ patientId, equipmentId }) =>
    `patients/${patientId}/equipment/${equipmentId}`,
  baseType: C.PATIENT.DELETE_EQUIPMENT_ITEM,
  method: del,
  mapActionToKeyIn: ({ patientId, equipmentId }) => [patientId, equipmentId],
  transform: (_, { equipmentId }) => ({
    deletions: {
      equipment: [equipmentId]
    }
  })
});

export const deletePatientEquipmentItem =
  ({ patientId, equipmentId }) =>
    dispatch =>
      dispatch(delPatientEquipmentItem({ patientId, equipmentId })).then(() =>
        dispatch(invalidatePatientEquipment({ patientId }))
      );

export const updatePatientOrderHistory =
  (patientId, values) => async dispatch => {
    try {
      await put(`patients/${patientId}/edit-hcpcs-ordered`, values);
      dispatch(invalidatePatientOrderHistory({ patientId }));
      dispatch(fetchPatientOrderHistory({ patientId }));
    } catch (err) {
      dispatch(
        msg.errorMessage(`Error adding updating HCPC history. ${err.message}`)
      );
    }
  };

// export const addPatientOrderHistory =
//   (patientId, values, onSuccess) => async dispatch => {
//     try {
//       await post(`patients/${patientId}/hcpcs-ordered`, values);
//       dispatch(invalidatePatientOrderHistory({ patientId }));
//       dispatch(fetchPatientOrderHistory({ patientId }));
//       dispatch(msg.message("Patient HCPCP History record added."));
//       if (typeof onSuccess == "function") onSuccess();
//     } catch (err) {
//       dispatch(
//         msg.errorMessage(
//           getApiErrorMessage(err, "Error adding patient HCPC History record")
//         )
//       );
//     }
//   };

export const updatePatientMaskFitElegibility =
  ({
    patientId,
    eligible,
    notify_patient = true,
    mask_type,
    blacklist_masks = [],
    blacklist_manufacturers = [],
    ahi,
    cmH2O
  }) =>
    async dispatch => {
      try {
        await put("/autofit/eligibility", {
          patient_id: patientId,
          eligible,
          notify_patient,
          mask_type,
          blacklist_masks: blacklist_masks.join("^"),
          blacklist_manufacturers: blacklist_manufacturers.join("^"),
          ahi,
          cmH2O
        });
        dispatch(invalidatePatientProfileInfo({ patientId }));
        dispatch(fetchPatientProfileInfo({ patientId }));
      } catch (err) {
        dispatch(msg.errorMessage(`Failed to update patient. ${err.message}`));
      }
    };

export const sendPatientMaskFittingInstructions =
  patientId => async dispatch => {
    try {
      await put("notifications", {
        patient_id: patientId,
        notification_type: "Autofit_Eligible",
        title: null,
        description: null,
        to_dme: false
      });
      dispatch(
        msg.message("Successfully sent patient mask fitting instructions")
      );
    } catch (e) {
      dispatch(
        msg.errorMessage(
          "Sorry something went wrong, failed to send mask fitting intructions"
        )
      );
    }
  };

export const setPatientFittingRecommendedMask =
  ({ patientId, transaction_id, inventory_id }) =>
    async dispatch => {
      try {
        await put(`autofit/results/${patientId}`, {
          transaction_id,
          mask_id: inventory_id
        });
        dispatch(msg.message("Successfully set patients recommended mask"));
        dispatch(invalidatePatientMaskFittingResults({ patientId }));
        dispatch(fetchPatientMaskFittingResults({ patientId }));
        dispatch(invalidatePatientEquipment({ patientId }));
        dispatch(fetchPatientEquipment({ patientId }));
        // dispatch(invalidatePatientProfileInfo({ patientId }));
        // dispatch(fetchPatientProfileInfo({ patientId }));
      } catch (e) {
        dispatch(
          msg.errorMessage(
            "Sorry something went wrong, failed to set recommended mask"
          )
        );
      }
    };

export const editPatient = (patientId, values) => async dispatch => {
  try {
    await put(`patients/${patientId}`, values);
    dispatch(msg.message("Successfully updated patient"));
    browserHistory.push(`/patients/${patientId}`);
    dispatch(invalidatePatientProfileInfo({ patientId }));
    dispatch(fetchPatientProfileInfo({ patientId }));
    // dispatch(invalidatePatientHCPCSRecords({ patientId }));
    // dispatch(fetchPatientHCPCSRecords({ patientId }));
  } catch (e) {
    dispatch(
      msg.errorMessage(`Failed to update patient. ${e.response.body.status}`)
    );
  }
};

export const createPatient = values => async dispatch => {
  try {
    const { id } = await post("patients", values);
    dispatch(msg.message("Successfully created patient"));
    browserHistory.push(`/patients/${id}`);
  } catch (e) {
    e.response.body.status
      ? dispatch(msg.errorMessage(e.response.body.status))
      : dispatch(
        msg.errorMessage(
          `Sorry something went wrong, failed to create patient ${e.response.body.status}`
        )
      );
  }
};

export const philipsRecall = values => async dispatch => {
  await put("patients/phillips_recall_update", values);
  dispatch(invalidatePatientProfileInfo({ patientId: values.patientId }));
  dispatch(fetchPatientProfileInfo({ patientId: values.patientId }));
};

export const updatePhilipsRecall =
  (values, fetchProfile = false) =>
    async dispatch => {
      await put("patients/phillips_recall_update", values);
      if (fetchProfile) {
        dispatch(invalidatePatientProfileInfo({ patientId: values.patientId }));
        dispatch(fetchPatientProfileInfo({ patientId: values.patientId }));
      }
    };

export const philipsRecallReset = values => async dispatch => {
  await put("patients/phillips_recall_update", values);
  dispatch(invalidatePatientProfileInfo({ patientId: values.patientId }));
  dispatch(fetchPatientProfileInfo({ patientId: values.patientId }));
};

export const submitPhilipsRecall =
  (patientId, values, fetchProfile = false) =>
    async dispatch => {
      await post(`patients/philips_recall/${patientId}`, values);
      if (fetchProfile) {
        dispatch(invalidatePatientProfileInfo({ patientId }));
        dispatch(fetchPatientProfileInfo({ patientId }));
      }
    };

export const resetPhilipsRecall =
  (patientId, fetchProfile = false) =>
    async dispatch => {
      await put(`patients/reset_philips_recall/${patientId}`, { patientId });
      if (fetchProfile) {
        dispatch(invalidatePatientProfileInfo({ patientId }));
        dispatch(fetchPatientProfileInfo({ patientId }));
      }
    };
