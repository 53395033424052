import React from "react";
import PropTypes from "prop-types";
import { isValidPhone } from "utils/redux-form";
import { InlineWaiting } from "components/ui";
import { browserHistory } from "browser-history";
import { STATES } from "utils/enum";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import PhoneNumberField from "components/forms/phone-number-field";
import * as Yup from "yup";

const BranchForm = ({ onSubmit, initialValues }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        branch_name: Yup.string().required("Required"),
        cpap_account_number: Yup.string().when(
          "uses_cpap_fulfillment" && "bUsesBranchFulfillment",
          {
            is: val => val === true,
            then: () => Yup.string().required("Please enter an account number.")
          }
        ),
        internal_account_number: Yup.string().when(
          "uses_internal_fulfillment" && "bUsesBranchFulfillment",
          {
            is: val => val === true,
            then: () => Yup.string().required("Please enter an account number.")
          }
        ),
        independence_account_number: Yup.string().when(
          "uses_independence_fulfillment" && "bUsesBranchFulfillment",
          {
            is: val => val === true,
            then: () => Yup.string().required("Please enter an account number.")
          }
        ),
        mckesson_account_number: Yup.string().when(
          "uses_mckesson_fulfillment" && "bUsesBranchFulfillment",
          {
            is: val => val === true,
            then: () => Yup.string().required("Please enter an account number.")
          }
        ),
        ppm_account_number: Yup.string().when(
          "uses_ppm_fulfillment" && "bUsesBranchFulfillment",
          {
            is: val => val === true,
            then: () => Yup.string().required("Please enter an account number.")
          }
        ),
        vgm_account_number: Yup.string().when(
          "uses_vgm_fulfillment" && "bUsesBranchFulfillment",
          {
            is: val => val === true,
            then: () => Yup.string().required("Please enter an account number.")
          }
        ),
        outbound_phone_number: Yup.string()
          .test(
            "valid-number",
            "Invalid phone number",
            val => !isValidPhone(val)
          )
          .nullable(true),
        contact_number: Yup.string()
          .test(
            "valid-contact-number",
            "Invalid contact number",
            val => !isValidPhone(val)
          )
          .nullable(true),
        outreach_end_time: Yup.string()
          .test({
            message: "Requried if Outreach Start Time is set",
            test: function () {
              const { outreach_end_time, outreach_start_time } = this.parent;
              if (!!outreach_start_time != !!outreach_end_time) return false;
              else return true;
            }
          })
          .nullable(true)
      })}
    >
      {({ values, isSubmitting }) => (
        <Form className="branch-form">
          <fieldset>
            <legend>Basics</legend>
            <FormikField label="Name" name="branch_name" type="text" />
            <FormikField
              label="Friendly Name"
              name="friendly_name"
              type="text"
            />

            <FormikField label="Tag" name="tag" type="text" />
            <FormikField
              label="Nonfulfillment Account Number"
              labelAppend="This account number is not to be used for fulfillment purposes"
              name="account_number"
              type="text"
            />
            <FormikField
              label="Active"
              name="active"
              type="checkbox"
              checked={values.active}
            />

            <FormikField
              name="outbound_phone_number"
              label="Outbound Phone Number"
              type="tel"
              component={PhoneNumberField}
            />
            <FormikField
              name="outreach_start_time"
              label="Outreach Start Time"
              type="time"
              step="3600000"
              as="input"
            />
            <FormikField
              name="outreach_end_time"
              label="Outreach End Time"
              type="time"
              step="3600000"
              as="input"
            />
            <FormikField
              label="Contact Number"
              name="contact_number"
              type="tel"
              component={PhoneNumberField}
            />
          </fieldset>
          {values?.bUsesBranchFulfillment ? (
            <fieldset>
              <legend>Fulfillment Companies</legend>
              <div>
                <div>
                  {values?.uses_cpap_fulfillment ? (
                    <FormikField
                      label="CPAP Dropship Account Number"
                      name="cpap_account_number"
                      as="input"
                      type="text"
                      validate={values?.uses_cpap_fulfillment && [true]}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {values?.uses_independance_fulfillment ? (
                    <FormikField
                      label="Independence Medical Account Number"
                      name="independance_account_number"
                      as="input"
                      type="text"
                      validate={values?.uses_independance_fulfillment && [true]}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {values?.uses_internal_fulfillment ? (
                    <FormikField
                      label="Internal Account Number"
                      name="internal_account_number"
                      as="input"
                      type="text"
                      validate={values?.uses_internal_fulfillment && [true]}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {values?.uses_mckesson_fulfillment ? (
                    <FormikField
                      label="McKesson Account Number"
                      name="mckesson_account_number"
                      as="input"
                      type="text"
                      validate={values?.uses_mckesson_fulfillment && [true]}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {values?.uses_ppm_fulfillment ? (
                    <FormikField
                      label="PPM Account Number"
                      name="ppm_account_number"
                      as="input"
                      type="text"
                      validate={values?.uses_ppm_fulfillment && [true]}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {values?.uses_vgm_fulfillment ? (
                    <FormikField
                      label="VGM Account Number"
                      name="vgm_account_number"
                      as="input"
                      type="text"
                      validate={values?.uses_vgm_fulfillment && [true]}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </fieldset>
          ) : (
            ""
          )}
          <fieldset>
            <legend>Insurance Information</legend>
            <div className="insurance-fields">
              <FormikField
                name="taxonomy_code"
                label="Taxonomy Code"
                type="text"
              />
              <FormikField name="npi" label="NPI" type="text" />
              <FormikField name="tax_id" label="Tax ID" type="text" />
            </div>
          </fieldset>
          <fieldset>
            <legend>Address</legend>
            <FormikField
              label="Street Address"
              name="address.street_address"
              className="street-address"
              type="text"
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 5
              }}
            >
              <FormikField label="City" name="address.city" type="text" />
              <FormikField
                label="State"
                name="address.state"
                component="select"
              >
                <option />
                {STATES.map(x => (
                  <option key={x.value} value={x.value}>
                    {x.text}
                  </option>
                ))}
              </FormikField>
              <FormikField label="Zip Code" name="address.zip" type="number" />
            </div>
          </fieldset>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <>
                <button
                  className="button warning"
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    browserHistory.goBack();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  type="submit"
                  style={{ marginLeft: 20 }}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BranchForm;

BranchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
};
