import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import IconButton from "components/ui/icon-button";
import { formatPhone } from "utils/misc";
import SectionHeader from "components/profile/section_header";
import { Ul, AttributeItem } from "components/lists";
import MdPhoneForwarded from "react-icons/lib/md/phone-forwarded";
import { setTransferNumber } from "actions/phone";
import ModalLink from "components/shared/modal-link";
import HasRolePermission from "components/shared/has-role-permission";
import PatientLoginRecords from "./login-records";

const AccountDetails = ({ patientId, patientInfo, setTransferNumber }) => {
  return (
    <div>
      <SectionHeader>Account Details</SectionHeader>
      <Ul>
        <AttributeItem title="Company">
          <HasRolePermission
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "OfficeAdministrator",
              "ServiceAdmin",
              "SleepCoach",
              'ContractorAdministrator'
            ]}
            renderElse={<span>{patientInfo.company_name}</span>}
          >
            <ModalLink to={`/companies/${patientInfo.company_id}/profile`}>
              {patientInfo.company_name}
            </ModalLink>
          </HasRolePermission>
        </AttributeItem>
        <AttributeItem title="Account Number:">
          {patientInfo.account_number}
        </AttributeItem>
        <AttributeItem
          title="Company Phone:"
          actions={
            <IconButton
              title="Add number to Transfer Input Box"
              icon={<MdPhoneForwarded />}
              onClick={() => {
                setTransferNumber(patientInfo.company_outbound_phone_number);
              }}
              style={{ fontSize: "12px", float: "right", color: "green" }}
            />
          }
        >
          {formatPhone(patientInfo.company_outbound_phone_number)}
        </AttributeItem>
        <AttributeItem title="Branch:">
          {patientInfo.branch_office_name}
        </AttributeItem>
        <AttributeItem title="Sleep Coach:">
          {patientInfo.sleep_coach_name}
        </AttributeItem>
        <AttributeItem title="Respiratory Therapist:">
          {patientInfo.therapist_name}
        </AttributeItem>
        <AttributeItem title="Eligible Date:">
          {patientInfo.eligible_after &&
            moment(patientInfo.eligible_after).format("L")}
        </AttributeItem>
        <AttributeItem title="Order Cycle:">
          {patientInfo.orderCycle}
        </AttributeItem>
        <AttributeItem title="Last Login">
          {patientInfo.last_sign_in_at
            ? moment(patientInfo.last_sign_in_at).format("L")
            : "never"}
        </AttributeItem>
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "SleepCoach",
            "OfficeAdministrator",
            "CompanyAdministrator",
            "ServiceAdmin",
            "ServiceCoach",
            'ContractorAdministrator'
          ]}
        >
          <PatientLoginRecords patientId={patientId} />
        </HasRolePermission>
      </Ul>
    </div>
  );
};

AccountDetails.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  setTransferNumber: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

AccountDetails.defaultProps = {
  readOnly: false
};

export default connect(() => ({}), { setTransferNumber })(AccountDetails);
